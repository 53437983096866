import React, { useState, useEffect, useCallback } from "react";
import ReactPixel from "react-facebook-pixel";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import {
  Carousel,
  Image,
  Jumbotron,
  Card,
  Accordion,
  Button,
  Spinner,
  Table,
} from "react-bootstrap";
import "./ProductPage.css";
import StarRatingComponent from "react-star-rating-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGraduate,
  faCheck,
  faLock,
  faUnlock,
  faCalendar,
  faCalendarDay,
  faBusinessTime,
  faCheckCircle,
  faListAlt,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import VideoScrollList from "../components/VideoScrollList";
import { s3GetPublicFile } from "../libs/awsLib";
import { create_topics_map } from "../libs/orderTopicsLib";
import MathJax from "react-mathjax-preview";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useHistory } from "react-router-dom";
import { ItemInfo } from "../components/FormComponents";
import { formatDate } from "../libs/utilsLib";
import FacebookReviewsCarousel from "../components/FacebookReviewsCarousel";
import LoginModal from "./unauthenticated/LoginModal";
import { fbPixelAddToCart, fbPixelViewContent } from "../libs/facebookPixelLib";

export default function ProductPage() {
  const history = useHistory();
  const { id } = useParams();
  const { isAuthenticated, userInfo, setShowShoppingBag, userEnrolledCourses } =
    useAppContext();
  const [product, setProduct] = useState(null);
  const [topics_array, set_topics_array] = useState([]);
  const [topicsMapById, setTopicsMapById] = useState({});
  const [topicsMapByName, setTopicsMapByName] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isInscribeButtonLoading, setIsInscribeButtonLoading] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [relatedProducts, setRelatedProducts] = useState(null);
  const [coupons, setCoupons] = useState(null);
  const [category, setCategory] = useState(null);
  const [teacherProfile, setTeacherProfile] = useState(null);

  const callGetPublicTopicListAPI = useCallback(
    async (course) => {
      try {
        const topics_unordered = await API.get(
          "virtualtech",
          `/course_topics/list_public/${id}`
        );

        create_topics_map(
          topics_unordered,
          course,
          setTopicsMapById,
          setTopicsMapByName,
          set_topics_array
        );
      } catch (e) {
        console.log("error in getting topics");
        console.log(e);
      }
    },
    [id]
  );

  const addToShoppingBagAPI = useCallback(
    async (products_keys_list) => {
      setIsInscribeButtonLoading(true);

      try {
        await API.post("virtualtech", `/shopping-bag`, {
          body: {
            products_keys_list: products_keys_list,
            userData: userInfo,
          },
        });
        setIsInscribeButtonLoading(false);
        setShowShoppingBag(true);
      } catch (error) {
        setIsInscribeButtonLoading(false);
        console.log("Couldn't add to shoppingBag");
      }
    },
    [setShowShoppingBag, userInfo]
  );

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      try {
        const response = await API.get("virtualtech", `/products/${id}`);
        const product = response.product;

        for (let i = 0; i < product.portrait_pictures.length; i++) {
          const portrait_picture = product.portrait_pictures[i].image_key;
          try {
            let image = await s3GetPublicFile(
              portrait_picture,
              product.creator_id
            );
            product.portrait_pictures[i] = image;
          } catch (error) {
            console.log(error);
            console.log("error al obtener imágenes de cursos");
          }
        }
        setProduct(product);
        setCoupons(response?.coupons);
        setCategory(response?.category);
        const relatedProducts = response?.relatedProducts;

        if (relatedProducts) {
          for (let i = 0; i < relatedProducts.length; i++) {
            const course = relatedProducts[i];
            try {
              let image = await s3GetPublicFile(course.mini_picture);
              course.mini_picture = image;
            } catch (error) {
              console.log(error);
              console.log("error al obtener imágenes de cursos");
            }
          }
          setRelatedProducts(relatedProducts);
        }

        await callGetPublicTopicListAPI(product);

        const teacherProfile = response.teacherProfile;
        if (teacherProfile && id !== "ingenieria-matematica-basica-1") {
          await getTeacherProfileImage(teacherProfile);
        }
      } catch (e) {
        console.log("error in getting course");
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }

    async function getTeacherProfileImage(teacherProfile) {
      try {
        const profileImageUrl = await s3GetPublicFile(
          teacherProfile.profile_image_key
        );
        setTeacherProfile({ ...teacherProfile, profileImageUrl });
      } catch (error) {
        console.log("Error in getTeacherProfileImage()", error);
      }
    }

    const pending_products_keys_list = JSON.parse(
      localStorage.getItem("pending_products_keys_list")
    );
    if (pending_products_keys_list && pending_products_keys_list?.length > 0) {
      addToShoppingBagAPI(pending_products_keys_list);
      localStorage.setItem("pending_products_keys_list", null);
    }

    ReactPixel.pageView();
    fbPixelViewContent("product", [id]);
    onLoad();
    window.scrollTo(0, 0);
  }, [addToShoppingBagAPI, callGetPublicTopicListAPI, id]);

  function renderTeacherProfile(teacherProfile) {
    if (
      teacherProfile.name &&
      teacherProfile.lastname &&
      teacherProfile.profileImageUrl &&
      teacherProfile.description
    ) {
      return (
        <>
          <h4 className="mt-5 mb-5">Acerca del Profesor</h4>
          <div className="row">
            <div className="col-12 col-md-4 ">
              <Image
                className="mt-1 mb-4 px-5"
                fluid
                src={teacherProfile.profileImageUrl}
                roundedCircle
              />
            </div>
            <div className="col-12 col-md-8">
              <h5 className="mb-3 mb-md-4 mt-3">
                {`${teacherProfile.name} ${teacherProfile.lastname}`}{" "}
              </h5>
              <MathJax className="mt-3" math={teacherProfile.description} />
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }

  function renderImages() {
    return product.portrait_pictures.map((picture, i) => (
      <Carousel.Item key={i} className="my-0 py-0">
        <div className="image-div my-0 py-0 mx-0 px-0">
          <Image
            src={picture}
            className="my-image my-0 py-0 w-100"
            rounded
            fluid
          />
        </div>
      </Carousel.Item>
    ));
  }

  function renderImportantPoints() {
    return product.important_points.map((point, i) => (
      <div className="col-6" key={i}>
        <div className="row no-gutters">
          <div className="col-1">
            <FontAwesomeIcon icon={faCheck} className="pr-1 " />
          </div>
          <div className="col-11">
            <p>{point.text}</p>
          </div>
        </div>
      </div>
    ));
  }

  function renderTopics() {
    return topics_array.map((topic, i) => (
      <Card key={i}>
        <Accordion.Toggle as={Card.Header} eventKey={i} className="pt-3 pb-1 ">
          <div
            className={`row pb-0 ${
              topic.is_public ? "text-warning" : "text-muted"
            }`}
          >
            <div className="col-10 col-md-11">
              <p>{topic.topic_name}</p>
            </div>
            <div className="col-2 col-md-1">
              <FontAwesomeIcon icon={topic.is_public ? faUnlock : faLock} />
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={i}>
          {topic.is_public ? <p>contenido</p> : <></>}
          {/* {topic.content ? (
            <Card.Body>
              <p>{topic.content.description}</p>
              {topic.content.type === "video" ? (
                <Vimeo video={topic.content.id} responsive className="p-0" />
              ) : (
                <></>
              )}
            </Card.Body>
          ) : (
            <></>
          )} */}
        </Accordion.Collapse>
      </Card>
    ));
  }

  async function handleInscriptionClicked(products_keys_list) {
    if (isAuthenticated) {
      await addToShoppingBagAPI(products_keys_list);
      fbPixelAddToCart("product", products_keys_list);
    } else {
      console.log(products_keys_list);
      localStorage.setItem(
        "pending_products_keys_list",
        JSON.stringify(products_keys_list)
      );
      localStorage.setItem("previousRedirectURL", `/cursos/${id}`);
      setShowLoginModal(true);
      // history.push("/login?redirect=/completar-inscripcion");
    }
  }

  function renderRelatedProductsAndOffers() {
    return (
      <>
        {relatedProducts && coupons && (
          <>
            {category?.category_name && (
              <h4 className="text-capitalize  mt-5 mb-5">{`Ofertas ${category.category_name}`}</h4>
            )}

            <div className="row">
              <div className=" col-12 col-md-9 ">
                <div className="row">
                  {relatedProducts.map((relatedProduct, index) => (
                    <div
                      className="col-6 mb-4  mb-md-0 col-md-3"
                      key={relatedProduct.product_id}
                    >
                      <Card className="h-100 rounded-lg">
                        <Card.Img
                          variant="top"
                          src={relatedProduct.mini_picture}
                          className="rounded-top-lg"
                        />
                        <Card.Body className="px-3 pt-2 pb-2 d-flex flex-column justify-content-center">
                          <p className="text-center">
                            {" "}
                            <strong> {relatedProduct.name} </strong>{" "}
                          </p>

                          {userEnrolledCourses[relatedProduct.product_id] ? (
                            <LoaderButton
                              className="center py-2 text-capitalize"
                              variant="success"
                              onClick={() => {
                                history.push(
                                  `/mis-cursos/${relatedProduct.product_id}/dashboard`
                                );
                              }}
                            >
                              Ir Al Curso
                            </LoaderButton>
                          ) : (
                            <>
                              {id !== relatedProduct.product_id ? (
                                <Button
                                  className="center pb-3 pt-0 text-capitalize"
                                  variant="link"
                                  onClick={() =>
                                    history.push(
                                      `/cursos/${relatedProduct.product_id}`
                                    )
                                  }
                                >
                                  Ver Más
                                </Button>
                              ) : (
                                <div className="center pb-3 pt-4"></div>
                              )}

                              <LoaderButton
                                className="center py-2 text-capitalize"
                                variant="success"
                                onClick={() => {
                                  let productsIdsArray = [];
                                  productsIdsArray.push(
                                    relatedProduct.product_id +
                                      "&" +
                                      relatedProduct.creator_id
                                  );
                                  handleInscriptionClicked(productsIdsArray);
                                }}
                              >
                                Agregar
                              </LoaderButton>
                            </>
                          )}
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-3 ">
                <Table responsive>
                  <thead>
                    <tr className="table-warning">
                      <th className="text-center">Cantidad </th>
                      <th className="text-center">Oferta </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">1 Curso</td>
                      <td className="pl-5 text-center">
                        {"Q " + product.price}
                      </td>
                    </tr>
                    {coupons.map((coupon, index) => (
                      <tr key={index}>
                        <td className="text-center">{`${coupon?.quantity_of_items} Cursos`}</td>
                        <td>
                          <div className="d-flex flex-row align-items-center justify-content-center">
                            <p
                              style={{ fontSize: "90%" }}
                              className="text-center text-secondary mr-2"
                            >
                              <s>
                                {`Q ${
                                  coupon?.quantity_of_items * product.price
                                }`}
                              </s>
                            </p>
                            <p>
                              {`Q ${
                                coupon?.quantity_of_items * coupon?.unit_price
                              }`}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  function renderBigVideoPromo() {
    return (
      <div className="col-12 my-5">
        {product?.big_video_promo?.title && (
          <h4 className="mb-5">
            <strong>{product?.big_video_promo?.title}</strong>
          </h4>
        )}
        {product?.big_video_promo?.vimeo_video_id && (
          <div className="mx-0 mx-md-3">
            <Card className="p-0">
              <Card.Body className="p-0">
                <Vimeo
                  video={product?.big_video_promo?.vimeo_video_id}
                  responsive
                  className="p-1"
                />
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    );
  }

  const getFinalizeValue = () => {
    if (!product.finish_date) {
      return product.finish_date;
    }
    const date = new Date(product.finish_date);
    const month = date.toLocaleString("default", { month: "long" });
    return `${date.getUTCDate()} ${month}`;
  };

  function renderProduct() {
    return (
      <div className="ProductPage mb-5">
        <div className="">
          <Carousel
            controls={false}
            className=" mt-3 carousel pb-0 mb-0"
            interval={3000}
          >
            {renderImages()}
          </Carousel>
          <Jumbotron className=" pt-3 pt-md-1 mt-0 pb-0  jumbotron">
            <h1 className="text-center  py-md-3 py-4 product-name ">
              {product.name}
            </h1>
            <p className="lead pb-0 my-0">{product.description}</p>
            <div className="row px-2  pt-4">
              <div className="col-md-6 col-sm-12">
                {product.stars > 3 && (
                  <div className="row justify-content-center">
                    <p className="text-center">
                      <strong className="text-center">
                        {product.stars + "   "}
                      </strong>{" "}
                    </p>
                    <StarRatingComponent
                      className="text-center pl-1"
                      name="ratio1"
                      editing={false}
                      starCount={5}
                      value={Math.round(product.stars)}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6 col-sm-12 pl-0 ">
                {product.students_count > 5 && (
                  <p className="text-center ">
                    <small>
                      {"Se han inscrito "}
                      <strong>{product.students_count + "+   "}</strong>
                      <FontAwesomeIcon icon={faUserGraduate} />
                    </small>
                  </p>
                )}
              </div>
            </div>
          </Jumbotron>
        </div>

        <div className="col-12 mb-3 mt-5">
          <div className="row">
            <div className="col-md-5 col-sm-12 mb-3 order-md-2 px-0 ">
              <Card className="p-0">
                <Card.Body className="p-1">
                  {product?.promo_video_id && (
                    <Vimeo
                      video={product.promo_video_id}
                      responsive
                      className="p-0"
                    />
                  )}

                  <div className="container pb-3 pt-4 px-2">
                    {product?.worst_price && (
                      <h4 className="text-center pt-2 font-weight-light text-secondary">
                        <s>{`Q ${product?.worst_price}`}</s>
                      </h4>
                    )}
                    <h3 className="text-center pt-1 pb-3 font-weight-light">{`Q ${product.price}`}</h3>
                    {userEnrolledCourses[id] ? (
                      <LoaderButton
                        size="lg"
                        block
                        variant="success"
                        className="text-capitalize mb-4"
                        isLoading={isInscribeButtonLoading}
                        onClick={() => {
                          history.push(`/mis-cursos/${id}/dashboard`);
                        }}
                      >
                        Ir Al Curso
                      </LoaderButton>
                    ) : (
                      <LoaderButton
                        size="lg"
                        block
                        variant="success"
                        className="text-capitalize mb-4"
                        isLoading={isInscribeButtonLoading}
                        onClick={() => {
                          let productsIdsArray = [];
                          productsIdsArray.push(
                            product.product_id + "&" + product.creator_id
                          );
                          handleInscriptionClicked(productsIdsArray);
                        }}
                      >
                        Inscribirse
                      </LoaderButton>
                    )}

                    <ItemInfo
                      className=" pl-4 row"
                      text={"Primer Ingreso"}
                      icon={faCalendarDay}
                    />
                    <ItemInfo
                      className=" pl-4 row"
                      label="Finaliza"
                      text={getFinalizeValue()}
                      icon={faBusinessTime}
                    />
                    <ItemInfo
                      className=" pl-4 row"
                      text={product.coverage_period}
                      icon={faListAlt}
                    />
                    <ItemInfo
                      className=" pl-4 row"
                      text={"Único Pago, Material Todo Incluido"}
                      icon={faCheckCircle}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-7 col-sm-12  order-md-1  pr-md-5">
              <div className="row pt-2">{renderImportantPoints()}</div>
              <h4 className="mt-5 mb-3 pb-2">Contenido </h4>
              <div
                className="AccordionBottomShadow"
                style={{ maxHeight: "540px", overflowY: "auto" }}
              >
                <Accordion
                  className="pl-md-1 pr-md-2 py-2"
                  defaultActiveKey="0"
                >
                  {renderTopics()}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">{renderRelatedProductsAndOffers()}</div>

        <div className="col-12 mb-5">
          <h4 className="mt-5 mb-5">Descripción</h4>
          <div className="mx-md-2 ">
            <MathJax math={product.long_description} />
          </div>
        </div>

        {product?.big_video_promo && renderBigVideoPromo()}

        {product?.sample_videos?.length > 0 && (
          <div className="container-fluid">
            <h4 className="mt-5 mb-5">Videos</h4>
            <VideoScrollList videos={product.sample_videos} />
          </div>
        )}

        <div className="container mt-5 ">
          {/* {teacherProfile && renderTeacherProfile(teacherProfile)} */}
        </div>

        <div className="container mt-5 ">
          <FacebookReviewsCarousel isLight={true} />
        </div>
        <div className="container my-5 pt-3 px-0 px-md-5">
          {userEnrolledCourses[id] ? (
            <LoaderButton
              size="lg"
              block
              variant="success"
              className="text-capitalize mb-4"
              isLoading={isInscribeButtonLoading}
              onClick={() => {
                history.push(`/mis-cursos/${id}/dashboard`);
              }}
            >
              Ir Al Curso
            </LoaderButton>
          ) : (
            <LoaderButton
              size="lg"
              block
              variant="success"
              className="text-capitalize mb-4"
              isLoading={isInscribeButtonLoading}
              onClick={() => {
                let productsIdsArray = [];
                productsIdsArray.push(
                  product.product_id + "&" + product.creator_id
                );
                handleInscriptionClicked(productsIdsArray);
              }}
            >
              Inscribirse
            </LoaderButton>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="container px-0 mx-0 pb-5 mx-md-auto">
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        isInModal
      />
      {isLoading ? (
        <div className="container py-5 px-5 d-flex flex-row justify-content-center align-items-center">
          {" "}
          <Spinner className="my-5" animation="grow" variant="primary" />{" "}
        </div>
      ) : (
        <>{product && renderProduct()}</>
      )}
    </div>
  );
}

/*
<div class="main-text hidden-xs">
              <div class="col-md-12 text-center">
                <h2>{product.name}</h2>
              </div>
            </div>
*/
